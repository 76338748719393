import { Tooltip } from 'antd';
import { jsonrepair } from 'jsonrepair';
import { ReactNode } from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { Editable } from 'slate-react';
import styled, { css } from 'styled-components';

import theme from '../../assets/theme';
import { newRelicNoticeError } from '../../hooks/useNewRelic';
import { Prompt, SlideBullet } from '../../services/backendService/types';
import {
  getActiveNestedNode,
  getActiveNode,
  toggleMark,
  toggleSize,
  toggleType,
} from './richTextEditorUtil';
import { RichTextEditorType } from './SlateRichTextEditor';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'] as const;
export type TYPE_LIST_TYPE = typeof LIST_TYPES[number];
export const TEXT_ALIGN_LIST = ['left', 'center', 'right'] as const;

export type TEXT_ALIGN_TYPES = typeof TEXT_ALIGN_LIST[number];
export const SLATE_FONTSIZE_SCALE_FACTOR = 1;
export const ToolbarButtonsList = [
  'font-size',
  'colors',
  'emoji',
  'bold',
  'italic',
  'align', // Left, center and right
  'block-type', // bulleted-list and numbered-list
  'delete', // delete option used for poll
];
export type ToolbarButtonsType = typeof ToolbarButtonsList[number];
export type EditorInitialState = {
  align?: TEXT_ALIGN_TYPES;
  color?: string;
  size: number;
};

export interface CuripodText {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  label?: string;
  color?: string;
  link?: string;
}

export type CuripodPromptInputElement = {
  type: 'promptInput';
  inputId: string;
  label: string;
  children: CuripodDecendant[];
};

export type CuripodPromptOutputElement = {
  type: 'promptOutput';
  outputId: string;
  label: string;
  children: CuripodDecendant[];
};
export type CuripodPromptLiveElement = {
  type: 'livePromptInput';
  inputId: string;
  label: string;
  children: CuripodDecendant[];
};

export interface CuripodElement {
  align?: TEXT_ALIGN_TYPES;
  color?: string;
  type: string;
  size?: number;
  children: CuripodDecendant[];
}
export type CuripodDecendant =
  | CuripodElement
  | CuripodText
  | CuripodPromptInputElement
  | CuripodPromptOutputElement;

export interface RenderElementProps {
  children: CuripodElement[];
  element: SlateElement;
  attributes: Attributes;
}
/** Copied from Slate editable.ts*/

export const isCuripodText = (element: CuripodDecendant): element is CuripodText => {
  if (!!(element as CuripodText).text !== undefined) return true;
  return false;
};
export const isCuripodElement = (
  element: CuripodDecendant,
): element is CuripodElement => {
  if ((element as CuripodElement).type) return true;
  return false;
};

export const isCuripodPromptInputElement = (
  element: CuripodDecendant,
): element is CuripodPromptInputElement => {
  if (
    (element as CuripodPromptInputElement)?.type &&
    (element as CuripodPromptInputElement).type === 'promptInput'
  )
    return true;
  return false;
};

export const isCuripodPromptOutputElement = (
  element: CuripodDecendant,
): element is CuripodPromptOutputElement => {
  if (
    (element as CuripodPromptOutputElement)?.type &&
    (element as CuripodPromptOutputElement).type === 'promptOutput'
  )
    return true;
  return false;
};
export interface Attributes {
  'data-slate-node': 'element';
  'data-slate-inline'?: true;
  'data-slate-void'?: true;
  dir?: 'rtl';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any;
}
/** Copied from Slate editable.ts*/

export const EMPTY_SLATE_ELEMENT: CuripodElement = {
  type: 'paragraph',
  children: [{ text: ' ', color: theme.colors.black }],
  align: 'center',
  size: 80,
};

export const createEmptySlateParagraph = (initial?: {
  align?: string;
  color?: string;
  size?: number;
  text?: string;
}) => {
  return {
    type: 'paragraph',
    children: [
      { text: initial?.text || ' ', color: initial?.color || theme.colors.black },
    ],
    align: initial?.align || 'center',
    size: initial?.size || 80,
  };
};

export const createSimpleSlateParagraphAsText = (text: string) => {
  return JSON.stringify([
    {
      type: 'paragraph',
      children: [{ text }],
    },
  ]);
};

export const createSimpleSlateParagraphFromAIOutput = ({
  text,
  align,
  color,
  size,
}: {
  align?: TEXT_ALIGN_TYPES;
  color?: string;
  text: string;
  size?: number;
}): CuripodElement => {
  return {
    type: 'paragraph',
    children: [{ text, color }],
    align,
    size,
  };
};

export const Element = ({
  attributes,
  children,
  element,
  className,
}: {
  attributes: Attributes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  element: CuripodElement;
  className?: string;
}) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'bulleted-list':
      return (
        <UnorderedList
          style={style}
          {...attributes}
          $fontSize={element.size}
          className={className}
          $align={element.align}
          $color={element.color}
        >
          {children}
        </UnorderedList>
      );
    case 'numbered-list':
      return (
        <OrderedList
          style={style}
          {...attributes}
          $fontSize={element.size}
          className={className}
          $align={element.align}
          $color={element.color}
        >
          {children}
        </OrderedList>
      );
    case 'list-item':
      return (
        <ListItem
          style={style}
          $color={element.color}
          {...attributes}
          $fontSize={element.size}
          $align={element.align}
          className={className}
        >
          {children}
        </ListItem>
      );
    default:
      return (
        <ParagraphElement
          {...attributes}
          style={style}
          $color={element.color}
          $fontSize={element.size}
          $align={element.align}
          className={className}
        >
          {children}
        </ParagraphElement>
      );
  }
};

export const Leaf = ({
  attributes,
  children,
  leaf,
  metrics,
}: {
  attributes: Attributes;
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  leaf: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metrics?: { logEvent: (value: string, options: any) => void };
}) => {
  if (leaf.bold) {
    children = (
      <SlateLeafSpan $color={leaf.color}>
        <strong>{children}</strong>
      </SlateLeafSpan>
    );
  }
  if (leaf.underline) {
    children = (
      <SlateLeafSpan $color={leaf.color}>
        <u>{children}</u>
      </SlateLeafSpan>
    );
  }
  if (leaf.italic) {
    children = (
      <SlateLeafSpan $color={leaf.color}>
        <em style={{ color: leaf.color }}>{children}</em>
      </SlateLeafSpan>
    );
  }
  if (leaf.link) {
    // check if windows is defined
    let pathname: string | undefined = undefined;
    if (typeof window !== 'undefined') {
      pathname = window?.location?.pathname;
    }

    children = (
      <Tooltip mouseEnterDelay={0.5} title={leaf.link}>
        <SlateLeafSpan
          $color={leaf.color !== theme.colors.black ? leaf.color : theme.colors.blueDark}
          onClick={() => {
            if (metrics) {
              metrics.logEvent('Slate.LinkClicked', { pathname, link: leaf.link });
            }
          }}
          style={{
            textDecoration: 'underline',
            filter: 'brightness(0.8)',
          }}
        >
          <a href={leaf.link} target="_blank" rel="noreferrer">
            {children}
          </a>
        </SlateLeafSpan>
      </Tooltip>
    );
  }
  return (
    <SlateLeafSpan $color={leaf.color} {...attributes}>
      {children}
    </SlateLeafSpan>
  );
};
// jens@curipod.com

const SlateLeafSpan = styled.span<{ $color?: string }>`
  color: ${({ $color }) =>
    $color}; // we dont set default black to allow for inheritance and backward compatibility with paragraph element color
  span::selection {
    color: ${({ $color }) => ($color ? $color : 'white')} !important;
    background-color: ${theme.colors.orange} !important;
  }
  a {
    color: ${({ $color }) => ($color ? $color : theme.colors.blueDark)};
  }
`;

export const getCurrentSizeDisplay = (currentSize?: number) => {
  return `${Math.ceil((SLATE_FONTSIZE_SCALE_FACTOR * (currentSize || 16)) / 2.5)}`;
};

export const getIsTextHighlighted = (editor: Editor) => {
  const { selection } = editor;
  if (!selection) return false;
  if (selection.anchor.offset === selection.focus.offset) return false;
  return true;
};

export const parseStringToSlateObject = (
  text?: string | null,
  initialEmptyState?: { align?: string; color?: string; size?: number },
): CuripodElement[] => {
  if (!text) {
    return JSON.parse(JSON.stringify([createEmptySlateParagraph(initialEmptyState)]));
  }
  if (text.startsWith('[{')) {
    try {
      return JSON.parse(text).filter((e: CuripodElement | null) => !!e);
    } catch (error) {
      try {
        const repaired = jsonrepair(text);
        return JSON.parse(repaired);
      } catch (error) {
        return JSON.parse(JSON.stringify([createEmptySlateParagraph(initialEmptyState)]));
      }
    }
  }

  /* Legacy handling */
  const newSlateString = JSON.parse(
    JSON.stringify([createEmptySlateParagraph({ ...initialEmptyState, text })]),
  );
  try {
    return JSON.parse(JSON.stringify(newSlateString));
  } catch (error) {
    newRelicNoticeError(error, 'Error parsing slate string, legacy handling');
    return newSlateString;
  }
};
export const getFirstSlateElementPropsFromSlateString = (text?: string | null) => {
  const slateObject = parseStringToSlateObject(text);
  const firstElement = slateObject[0];
  const firstElementFirstChild = firstElement.children?.[0];
  const textValue = isCuripodText(firstElementFirstChild)
    ? firstElementFirstChild?.text
    : undefined;
  return {
    text: textValue,
    align: firstElement.align,
    color: isCuripodText(firstElementFirstChild)
      ? firstElementFirstChild.color
      : firstElement.color,
    size: firstElement.size,
  };
};

//TODO: add unit tests
export const parseSlateStringToText = (slateText?: string | null) => {
  if (!slateText) return '';
  if (!slateText.startsWith('[{')) return slateText;
  try {
    const slateObject = JSON.parse(slateText) as CuripodElement[];
    return slateObject
      .flatMap(el => el.children)
      .map(e => (isCuripodElement(e) && e.type === 'list-item' ? e.children?.[0] : e))
      .filter(
        e =>
          isCuripodText(e) ||
          isCuripodPromptInputElement(e) ||
          isCuripodPromptOutputElement(e),
      )
      .map(e => {
        if (isCuripodPromptInputElement(e)) {
          return `"${e.label}"`;
        }
        if (isCuripodPromptOutputElement(e)) {
          return `"${e.label}"`;
        }
        if (isCuripodText(e)) {
          if (e.text.length === 0) return '\n\n'; //Force an empty paragraph to be a line break
          return e.text;
        }
      })
      .join('');
  } catch (error) {
    newRelicNoticeError(
      error,
      'Error parsing slate string to text',
      'parseSlateStringToText',
    );

    return '';
  }
};
export const selectAll = (editor: RichTextEditorType) => {
  Transforms.select(editor, {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  });
};

//TODO: add unit tests
export const parseOldBulletsToSlateBullets = (oldBullets: SlideBullet[]) => {
  try {
    const bulletList = [
      {
        type: 'bulleted-list',
        children:
          oldBullets && oldBullets.length > 0
            ? oldBullets.map((bullet: SlideBullet) => {
                return {
                  type: 'list-item',
                  size: 16,
                  children: [{ text: bullet.text, color: theme.colors.black }],
                };
              })
            : {
                type: 'list-item',
                size: 16,
                children: [{ text: '', color: theme.colors.black }],
              },
        size: 16,
        color: theme.colors.black,
      },
    ];

    return JSON.stringify(bulletList);
  } catch (error) {
    newRelicNoticeError(error, 'Error parsing old bullets to slate bullets');
  }
};

export const SlateEditable = styled(Editable)<{
  $readOnly?: boolean;
}>`
  height: 100%;
  word-break: break-word;
  cursor: text;
  span {
    font-weight: 600;
    overflow: hidden;
    word-break: break-word;
    width: auto;
  }
  strong {
    span {
      font-weight: 800;
    }
  }
  p {
    word-break: break-word;
    margin: 0;
  }
  font-size: 16px; //prevent iOs devices from autozooming while editing slides
`;

export const SlateWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ListItem = styled.li<{ $fontSize?: number; $color?: string; $align?: string }>`
  color: ${({ $color }) => ($color ? $color : 'black')};
  text-align: ${({ $align }) => ($align ? $align : 'center')};
`;

const UnorderedList = styled.ul<{ $fontSize?: number; $color?: string; $align?: string }>`
  margin: 0;
  color: ${({ $color }) => ($color ? $color : 'black')};
  ${({ $fontSize }) =>
    $fontSize &&
    css`
      font-size: ${$fontSize}px;
      margin-left: ${$fontSize}px;
    `}

  &::marker {
    ${({ $fontSize }) =>
      $fontSize &&
      css`
        font-size: ${$fontSize}px;
      `}
    color: ${({ $color }) => ($color ? $color : 'black')};
  }
  text-align: ${({ $align }) => ($align ? $align : 'center')};

`;
const OrderedList = styled.ol<{ $fontSize?: number; $color?: string; $align?: string }>`
   margin: 0;
  color: ${({ $color }) => ($color ? $color : 'black')};
  ${({ $fontSize }) =>
    $fontSize &&
    css`
      font-size: ${$fontSize}px;
      margin-left: ${$fontSize}px;
    `}

  &::marker {
    ${({ $fontSize }) =>
      $fontSize &&
      css`
        font-size: ${$fontSize}px;
      `}
    color: ${({ $color }) => ($color ? $color : 'black')};
  }
  text-align: ${({ $align }) => ($align ? $align : 'center')};
`;

export const ParagraphElement = styled.p<{
  $fontSize?: number;
  $color?: string;
  $align?: string;
}>`
  height: min-content;
  color: ${({ $color }) => ($color ? $color : 'black')};

  text-align: ${({ $align }) => ($align ? $align : 'center')};
  ${({ $fontSize }) =>
    $fontSize &&
    css`
      font-size: ${$fontSize}px;
    `}
`;

export const editFontSizeinSlateObject = ({
  slateObject,
  fontSize,
}: {
  slateObject: CuripodElement[];
  fontSize: number;
}): CuripodElement[] => {
  const newSlateObject = JSON.parse(JSON.stringify(slateObject));
  newSlateObject.forEach((el: CuripodElement) => {
    if (isCuripodElement(el)) {
      el.size = Math.ceil(fontSize);
    }
  });
  return newSlateObject;
};

export const handleSlateKeyPress = ({
  event,
  editor,
  disabledButtons = [],
  onDelete,
}: {
  event: React.KeyboardEvent<HTMLDivElement>;
  editor: RichTextEditorType;
  disabledButtons?: string[];
  onDelete?: () => void;
}) => {
  const ctrlKey = event.metaKey || event.ctrlKey;

  if (ctrlKey && event.key === 'b') {
    event?.preventDefault();
    toggleMark(editor, 'bold');
    return;
  }
  if (ctrlKey && event.key === 'i') {
    event?.preventDefault();
    toggleMark(editor, 'italic');
    return;
  }

  if (ctrlKey && event.key === 'ArrowUp' && !disabledButtons.includes('font-size')) {
    event?.preventDefault();
    toggleSize({ editor, increaseOrDecrease: 'increase' });
    return;
  }

  if (ctrlKey && event.key === 'ArrowDown' && !disabledButtons.includes('font-size')) {
    event?.preventDefault();
    toggleSize({ editor, increaseOrDecrease: 'decrease' });
    return;
  }

  if (event.code === 'Space' && !disabledButtons.includes('block-type')) {
    const active = getActiveNestedNode(editor);
    const type = active?.type;

    const marks = Editor.marks(editor);
    if (marks?.link) {
      toggleMark(editor, 'link', '');
    }
    if (type === 'paragraph' && active) {
      const child = active.children?.[0];
      const { selection } = editor;
      const path = selection?.focus.path;

      // Cursor is the first character in this block
      if (isCuripodText(child) && path?.[1] === 0) {
        const text = child.text;

        if (text.startsWith('*') || text.startsWith('-')) {
          event.preventDefault();
          toggleType({ editor, format: 'bulleted-list', currentContent: text.slice(1) }); // removes star
        } else if (text.startsWith('1.')) {
          event.preventDefault();
          toggleType({ editor, format: 'numbered-list', currentContent: text.slice(2) }); // removes 1. ...
        }
      }
    }
    return;
  }
  if (event.code === 'Backspace') {
    const active = getActiveNode(editor);
    const type = active?.type;

    // remove the link mark when deleting text
    const marks = Editor.marks(editor);
    if (marks?.link) {
      toggleMark(editor, 'link', '');
    }
    if (active && (type === 'bulleted-list' || type === 'numbered-list')) {
      // Remove bullet for paragraph if bullet is empty
      const { selection } = editor;
      const focusOffset = selection?.focus.offset;
      const anchorOffset = selection?.anchor.offset;
      if (focusOffset === 0 && anchorOffset === 0) {
        event.preventDefault();
        toggleType({ editor, format: active.type as TYPE_LIST_TYPE });
      }
    }
    /** Delete Poll optionsby backspace */
    if (
      !disabledButtons.includes('delete') &&
      onDelete &&
      active &&
      type === 'paragraph'
    ) {
      const child = active.children?.[0];
      if (isCuripodText(child)) {
        const text = child.text;
        if (!text) {
          onDelete();
        }
      }
    }
    return;
  }
  if (event.code === 'Delete') {
    //TODO: make delete function propperly
    const active = getActiveNode(editor);
    const textElement = active?.children?.[0];
    if (textElement && isCuripodText(textElement) && !textElement.text) {
      event.preventDefault();
    }
  }
  if (event.code === 'Enter') {
    const active = getActiveNode(editor);
    const type = active?.type;

    if (active && (type === 'bulleted-list' || type === 'numbered-list')) {
      // Removing last list-item in favour of paragraph if bullet is empty
      const { selection } = editor;
      const path = selection?.focus.path;
      const numberOfChildren = active.children.length;
      // Check that we are on the last bullet
      if (path?.[1] && path?.[1] + 1 === numberOfChildren) {
        const activeNested = getActiveNestedNode(editor);

        if (activeNested) {
          const textElement = activeNested?.children?.[0];
          // check that the text element is empty
          if (isCuripodText(textElement) && !textElement.text) {
            event.preventDefault();
            toggleType({ editor, format: active.type as TYPE_LIST_TYPE });
          }
        }
      }
    }
    return;
  }
};

// This function is copied from backend. as its used there for calculating height on text elements
export const charactersPerFullLine = (size: number) => {
  // Based on "e" on 1900 px
  // if OLD_SLATE_FONTSIZE_SCALE_FACTOR is set to 2000+ - we need to redo all these numbers
  if (size <= 20) return 178;
  if (size <= 30) return 119;
  if (size <= 40) return 89;
  if (size <= 50) return 71;
  if (size <= 60) return 59;
  if (size <= 80) return 44;
  if (size <= 100) return 35;
  if (size <= 140) return 25;
  if (size <= 180) return 19;
  if (size <= 220) return 16;
  if (size <= 300) return 11;
  return 1;
};

const charPerLineByWidth = (size: number, pxWidth: number) => {
  const k = 1900;
  const numCharactersForSize = charactersPerFullLine(size);

  const pxPerCharacter = (k / numCharactersForSize) * 0.9;

  // Round down to estimate a slighly smaller number of characters per line
  return Math.floor(pxWidth / pxPerCharacter);
};

const heightPxPerLineSize = (size: number) => {
  // if OLD_SLATE_FONTSIZE_SCALE_FACTOR is set to 2000+ - we need to redo all these numbers
  // Slate block size height in PX on 1080 x 1920 screen
  if (size <= 20) return 30.2;
  if (size <= 30) return 45.28;
  if (size <= 40) return 60.4;
  if (size <= 50) return 75.5;
  if (size <= 60) return 90.6;
  if (size <= 80) return 120.81;
  if (size <= 100) return 151.05;
  if (size <= 140) return 210;
  if (size <= 180) return 271.87;
  if (size <= 220) return 331;
  if (size <= 300) return 451.75;
  return 0;
};

/**
 * Estimates the height of a slate text string based on the width of the screen
 * its size of characters and lines.
 */
export const calculateHeightOfSlateText = (
  slateText: string | null | undefined,
  widthPercent: number,
): number => {
  if (!slateText) return 0;
  try {
    const pxWidth = 1920 * widthPercent;
    const slateObject = parseStringToSlateObject(slateText);
    const lineSizes = [] as { size: number }[];
    slateObject.forEach(block => {
      if (block.type === 'paragraph') {
        if (isCuripodText(block.children[0])) {
          const text = block.children[0].text;

          if (block.size) {
            const size = block.size;
            const charsPerLine = charPerLineByWidth(size, pxWidth);
            const lines = text.length === 0 ? 1 : Math.ceil(text.length / charsPerLine);
            new Array(lines).fill(0).forEach(() => {
              lineSizes.push({ size });
            });
          }
        }
      }
      if (block.type === 'bulleted-list' || block.type === 'numbered-list') {
        block.children.forEach(child => {
          if (isCuripodElement(child)) {
            const size = child.size;
            const firstText = child.children[0];
            if (size && isCuripodText(firstText)) {
              const text = firstText.text;
              const charsPerLine = charPerLineByWidth(size, pxWidth);
              const lines = text.length === 0 ? 1 : Math.ceil(text.length / charsPerLine);
              new Array(lines).fill(0).forEach(() => {
                lineSizes.push({ size });
              });
            }
          }
        });
      }
    });

    // Summarize the height of all lines that might contain different font sizes
    return lineSizes.reduce((acc, line) => {
      return acc + heightPxPerLineSize(line.size);
    }, 0);
  } catch (e) {
    return 0;
  }
};
export function sliceStringToNCharacters(inputString: string, n: number): string {
  if (inputString.length <= n) {
    return inputString;
  }

  const trimmedString = inputString.substr(0, n);
  const lastSpaceIndex = trimmedString.lastIndexOf(' ');

  if (lastSpaceIndex !== -1) {
    return trimmedString.substr(0, lastSpaceIndex) + '...';
  }

  return trimmedString + '...';
}

export const findAllSlateTextColors = (text?: string) => {
  if (!text) return [];
  const object = parseStringToSlateObject(text);
  if (!object) return [];
  const colors = [] as string[];
  object.forEach(block => {
    if (block.type === 'paragraph') {
      const color = block.color;
      if (color) {
        colors.push(color);
      }
      block.children.forEach(child => {
        if (isCuripodText(child)) {
          const color = child.color;
          if (color) {
            colors.push(color);
          }
        }
      });
    }
    if (block.type === 'bulleted-list' || block.type === 'numbered-list') {
      block.children.forEach(child => {
        if (isCuripodElement(child)) {
          const color = child.color;
          if (color) {
            colors.push(color);
          }
          child.children.forEach(child => {
            if (isCuripodText(child)) {
              const color = child.color;
              if (color) {
                colors.push(color);
              }
            }
          });
        }
      });
    }
  });
  return colors;
};

export const setColorOnAllSlateParagraphTexts = (
  slate: CuripodElement[],
  color: string,
) => {
  const mergedTextValue = slate.map(e => {
    if (e.type === 'paragraph') {
      return {
        ...e,
        children: e.children.map(c => {
          if (isCuripodText(c)) {
            return {
              ...c,
              color,
            };
          }
          return c;
        }),
      };
    }
    return e;
  });
  return mergedTextValue;
};

export const isValidUrl = (urlString: string) => {
  try {
    const protocols = ['http:', 'https:', 'ftp:'];
    const url = new URL(urlString);
    return protocols.includes(url.protocol);
  } catch (e) {
    return false;
  }
};

type UpdatePromptInputElementLabelByIdProps = {
  prompt: Prompt;
  id: string;
  updatedLabel: string;
};

export const updatePromptInputElementLabelById = ({
  prompt,
  id,
  updatedLabel,
}: UpdatePromptInputElementLabelByIdProps) => {
  return {
    ...prompt,
    messages: prompt.messages.map(eachMessage => {
      const slateObject = parseStringToSlateObject(eachMessage.content);
      const updatedSlateObject = slateObject.map(eachElement => {
        eachElement.children = eachElement.children.map(eachChild => {
          if (isCuripodPromptInputElement(eachChild)) {
            if (eachChild.inputId === id) {
              eachChild.label = updatedLabel;
            }
          }
          return eachChild;
        });
        return eachElement;
      });

      eachMessage.content = JSON.stringify(updatedSlateObject);
      return eachMessage;
    }),
  };
};

type UpdatePromptOutputElementLabelByIdProps = {
  prompt: Prompt;
  id: string;
  updatedLabel: string;
};
export const updatePromptOutputElementLabelById = ({
  prompt,
  id,
  updatedLabel,
}: UpdatePromptOutputElementLabelByIdProps) => {
  return {
    ...prompt,
    messages: prompt.messages.map(eachMessage => {
      const slateObject = parseStringToSlateObject(eachMessage.content);
      const updatedSlateObject = slateObject.map(eachElement => {
        eachElement.children = eachElement.children.map(eachChild => {
          if (isCuripodPromptOutputElement(eachChild)) {
            if (eachChild.outputId === id) {
              const originalLabelParts = eachChild.label.split(':');
              originalLabelParts[0] = updatedLabel;
              eachChild.label = originalLabelParts.join(':');
            }
          }
          return eachChild;
        });
        return eachElement;
      });

      eachMessage.content = JSON.stringify(updatedSlateObject);
      return eachMessage;
    }),
  };
};
